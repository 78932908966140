@import url(https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.a2k_link {
    text-decoration: none;
    color: black;
}

.beta_tag {
    font-weight: lighter;
}
.greeting-name {
    font-family: 'Dancing Script', cursive;
    font-size: xx-large;
}

.img-photo {
    height: 100px;
    border-radius: 50%;
}

@media only screen and (min-width: 981px) { 
    .img-photo {
        height: 500px;
        border-radius: 35px;
    }
    
}
.div-center {
    margin-left: auto;
    margin-right: auto;
}
.cover-page {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 25px;
}

.iframe-margin{
    margin-top: 20px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 20px;
}
.cover-page {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 25px;
}

.iframe-margin{
    margin-top: 20px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 20px;
}
.cover-page {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 25px;
}

.iframe-margin{
    margin-top: 20px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 20px;
}
