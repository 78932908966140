.cover-page {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 25px;
}

.iframe-margin{
    margin-top: 20px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 20px;
}